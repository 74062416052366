/*** IMPORTS FROM imports-loader ***/
var define = false;

        
export default {
    init: (app, Video) => {

        /*
        |
        | Video Popu
        |------------
        */
        const 
            $ficheVideoPopup = $('[data-item-video-popup]')
        ;

        if ($ficheVideoPopup.length >= 1 ) {
            $ficheVideoPopup.each(function(){
                var $self  = $(this);
                var option = {
                    options:{
                        popup   :  true
                    }
                };
                var video = new Video($self,option);

            });

        }

    }
}
