/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app,ScrollMagic, mobileDetect) => {

        

        /*
        |
        | ScrollMagic
        |------------
        */
        const controller  = new ScrollMagic.Controller(),
            $sections = $('.section-nav')
        ;
    
        var _mobil = new mobileDetect(),
            _width = $(window).width()
        ;

        if (_mobil.isMobile() == null && _width >= 992) {

            var $submenu  = $('#nav-resume')
            ;

            var scene = new ScrollMagic.Scene({
                'triggerElement': '#nav-resume',
                'triggerHook'   : 0,
                'offset':  $('#header').outerHeight() *-1, 
                'reverse': true
            })
            .setPin("#nav-resume")
            .addTo(controller)
            .on('enter', function(e){
                $submenu.addClass('is-fixed');
            })
            .on('leave', function(e){
                $submenu.removeClass('is-fixed');
            })
            //.addIndicators()
            ;
        }

        //console.log($sections);
        $sections.each(function(index){

            let _attr       = $(this).attr('id'),
                $this       = $('#'+_attr),
                _heightMar  = $this.outerHeight(true),
                _heightNo   = $this.outerHeight(),
                _duration   = _heightNo + ( _heightMar - _heightNo )*0.52 
            ;


            new ScrollMagic.Scene({
                triggerElement: "#"+_attr,
                duration: _duration
            })
            .setClassToggle('#nav-'+_attr, "active") // add class toggle
            //.addIndicators() // add indicators (requires plugin)
            .addTo(controller);

        });



    }
}
