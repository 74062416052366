/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Menu, Kira, ScrollMagic, CustomEase, customThree) => {
		/*
		|
		| Constants
		|-----------
		*/
        const 
            $body             = $('body'),
            $pageLoader       = $('.page-loader'),
            scrollAreas       = document.querySelectorAll('.scroll-area'),
            $splitItems       = $body.find('[data-splittext]'),
            $menuWrapper      = $('#mobile-menu'),
            $header           = $('#header'),
            $menuButton       = $header.find('.btn-menu'),
            $menuBars         = $menuButton.find('.item-burger > span'),
            $menuClose        = $menuWrapper.find('.item-close'),
            $menuStaggerItems = $menuWrapper.find('[data-stagger-item]'),
            $customCursor     = $('#customCursor')
        ;
        

        /*
		|
		| Easings
		|----------
        */
        CustomEase.create("easeCustom", "M0,0 C0.4,0 0.2,1 1,1")
        CustomEase.create("easeSmooth", "M0,0 C0.19,1 0.22,1 1,1") 
        CustomEase.create("easeCustomPower1", "M0,0 C0.165,0.84 0.44,1 1,1") 
        CustomEase.create("easeExpo", "M0, 0 C1, 0 0, 1 1, 1")


         /*
        |
        | customThree
        |------------
        if ($('[data-webgl]').length > 0) {
            $('[data-webgl]').each(function(index){
               new customThree($(this),{
                    color: $(this).data('color') ? parseInt ( $(this).data('color').replace("#","0x"), 16 ) : 0xffffff,
                    position: $(this).data('position') ? $(this).data('position') : 'bottom',
                    height: true
               });
            });
        }
        */
        /*
        |
        | customCursor
        |------------
        */
        //new customCursor($customCursor);


        /*
		|
		| SplitText
		|------------
        */
        $.each($splitItems, function() {
            const $item = $(this);
            const type = $item.data('splittext') ? $item.data('splittext') : 'lines, words';
            const split = new SplitText($item, { type: type, linesClass: 'item-line', wordsClass: 'item-word', charsClass: 'item-char' });

            $item[0]['split'] = split;
        });

        $body.on('loaderEnd', () => {
            $splitItems.addClass('split-ready')
        } )
		

		/*
        |
        | Scroll Areas
        |---------------
        */
		Array.prototype.forEach.call(scrollAreas, element => {
			Scrollbar.init(element);
		});


        /*
		|
		| Loader
		|---------
        */
        if (sessionStorage.getItem('loaded_once') === null) {
            sessionStorage.setItem('loaded_once', 'loaded_once');
        } 
        
        if ($pageLoader.hasClass('active')){
            const loaderTl = new TimelineMax({ paused: true, /*onComplete: () => $pageLoader.remove()*/ });

            loaderTl.to($pageLoader.find('.item-loadbar-inner'), 0.4, { scaleX: 1, ease: Power0.easeNone }, 'start')
            loaderTl.to($pageLoader.find('.item-content'), 0.8, { autoAlpha: 0, ease: Power1.easeOut }, '-=0')
            loaderTl.addCallback(() => { app.dispachEvent($body, 'loaderEnd'); })
            loaderTl.to($pageLoader, 0.8, { autoAlpha: 0, ease: Power1.easeOut }, '-=0')

            $(window).on('load', function () {
                loaderTl.play();
            });
        } else {
            $(window).on('load', function(){
                app.dispachEvent($body, 'loaderEnd');
            })
            
        }


        /*
		|
		| Menu
		|-------
        */
        const menu = new Menu($menuWrapper, $menuButton, {
            reverseTimeScale: 2
        });

        menu.menuTimeline
            .to($menuBars.eq(1), 0.3, { autoAlpha: 0 }, 'start')
			.to($menuBars.eq(0), 0.5, { x: 0, y: 8, rotation: 45, ease: Power1.easeOut }, 'start')
            .to($menuBars.eq(2), 0.5, { x: 0, y: -8, rotation: -45, ease: Power1.easeOut }, 'start')
			.to($menuWrapper, 0.5, { autoAlpha: 1, ease: Power1.easeOut }, 'start+=0.3')
            .staggerFrom($menuStaggerItems, 0.6, { autoAlpha: 0, x: 20, ease: Power1.easeOut }, '0.1', '+=0')
        
        menu.init();


        /*
        |
        | Class scroll menu
        |--------------
        */
        var controller   = new ScrollMagic.Controller();
        var sceneMenu     = new ScrollMagic.Scene({
            offset: 200,
            triggerHook: 0
        })
        .setClassToggle($header, 'floating slide-down')
        .addTo(controller);


        /*
		|
		| Kira
		|-------
        */
        const kira = new Kira(ScrollMagic, {
            debug: false,
            loadEvent: {
                domElement: $body,
                eventName: 'loaderEnd'
            },
            optimize: true,
            options: {
                start: '-=0.6',
                triggerHook: 'onEnter'
            }
        });

        /*
        | parallax
        |-----------------------
        */    
        var $parallax = $('[data-img-parallax]');
        $.each($parallax, function(){
            let $img = $(this);
            let maxValue = parseInt(getComputedStyle($img[0]).getPropertyValue('--overflow'), 10);
            let timeline  = new TimelineMax();
            timeline.fromTo($img, 0.1,
                    {y: '-' + maxValue },
                    {y: maxValue , ease: Linear.easeNone }, 0
            );
            
            new ScrollMagic.Scene({
                triggerElement: this , 
                triggerHook: 1,
                duration: "200%"
            })
            //.addIndicators()
            .setTween(timeline)
            .addTo(controller);

        });

          /*
        | parallax
        |-----------------------
        */
        var $parallaxCol = $('[data-content-parallax]');
        $.each($parallaxCol, function(){
            var $parent = $(this);
            var $list   = $(this).find('[parallax]');
            var _parent = this;

            $list.each(function(index){

                // clone 
                $(this).find('.circle-img').clone().appendTo($(this));
                let height_circle = $(this).find('.circle-img').height();
                let nb_circle = $(this).find('.circle-img').length;


                let variable = (index) % 2 == 0 ? 1 : 0.5;
                let maxValue = Math.abs($(this).height()-$parent.height()) * 0.5;
                

                let timeline  = new TimelineMax();
                timeline.fromTo($(this), 0.1,
                        {y: ( height_circle * -4 - (height_circle * variable) ) },
                        {y: ( height_circle * -2 - (height_circle * variable) ) , ease: Linear.easeNone }, 0
                );
           
                new ScrollMagic.Scene({
                    triggerElement: _parent , 
                    triggerHook: 1,
                    duration: "100%"
                })
               // .addIndicators()
                .setTween(timeline)
                .addTo(controller);

            });

        });

         /*
        | splitline
        |---------------
        */
        kira.add('splitline', ($item, timeline, start) => {
            const delay = $item.data('delay') ? $item.data('delay') : 0.1;
            $.each($item.find('.item-line'), function (key, value) {
                timeline.from($(this).find('> div, > em, > span'), 1.35, { y: '101%', ease: 'easeSmooth', delay: delay }, start)
            });
        });

        /*
        | fadeInUp.parallax.sm
        |-----------------------
        */
        kira.add('fadeInUp.parallax', ($item, timeline, start) => {
            timeline.fromTo($item,  0.6 , { y: 50 }, { y: 0, autoAlpha: 1, ease: Power1.easeOut }, start)
        });
        kira.add('fadeInUp.stagger.xs', ($item, timeline, start) => {
            timeline.staggerFromTo($item.find('[data-stagger-item]'), 0.6, {y: 20}, { autoAlpha: 1, y: 0, ease: Power1.easeOut }, '0.1', start)
        });
        kira.add('fadeInUp.stagger.sm', ($item, timeline, start) => {
            timeline.staggerFromTo($item.find('[data-stagger-item]'), 1, {y: 70}, { autoAlpha: 1, y: 0, ease: Power1.easeOut }, '0.1', start)
        });

        
        /*
        | counteur
        |--------------------
        */
        kira.add('counteur', ($item, timeline, start) => {
            let nb = $item.data('number');
            timeline.to($item, 3, {
                onUpdateParams:["{self}"],
                onUpdate:function(tl){
                    // tl references {self} which is the timeline
                    let tlp = tl.progress() * 100 >> 0;
                    let tmp; 
                    if (Number.isInteger(nb)) {
                        tmp = Math.round( ( tlp * nb / 100 ) )  ;
                    }else{
                        tmp = Math.round( ( tlp * nb / 100 )* 10) / 10 ;
                    }


                    $item.html(tmp);
                }                        
            },0);
            //console.log($item);
        });


    

        kira.init();
	}
}
