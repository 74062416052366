/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
import Collapse from 'bootstrap/js/dist/collapse.js';
require('@lib/iziModal/js/iziModal.js')($); //désolé
import isotope from "isotope-layout";
import Swiper from 'swiper/js/swiper.min';
import { TweenMax } from "gsap/TweenMax";
import CustomEase from "@lib/gsap-pro/CustomEase";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import SplitText from "@lib/gsap-pro/SplitText";
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/jquery.ScrollMagic.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
import ScrollMagic from 'scrollmagic';
import Scrollbar from 'smooth-scrollbar';

/*
|
| Importing Components
|-----------------------
*/
import * as app from '@components/global.js';
import CustomCanvas from '@components/custom-canvas';
import CustomCursor from '@components/custom-cursor';
import CookieManager from '@components/cookie-manager';
import customGoogleMap from '@components/custom-google-map.js';
import MaterializeForm from '@components/materialize-form.js';
import Kira from '@components/kira.js';
import Menu from '@components/menu.js';
import video from '@components/video-new.js';

/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import mobileDetect from '@components/mobile-detect';
import main from '@pages/main.js';
import news from '@pages/news.js';
import animations from '@pages/animations.js';
import home from '@pages/home.js';
import expertises from '@pages/expertises.js';
import single from '@pages/single.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
        'file': animations,
        'dependencies': [app, Menu, Kira, ScrollMagic, CustomEase, CustomCanvas]
    },
	{
		'file': main, 
		'dependencies': [app, CookieManager, Swiper, Collapse, ScrollToPlugin]
    },
    {
		'file': single, 
		'dependencies': [app, video],
		'resolve': '#page-single'
    },
    {
		'file': expertises, 
        'dependencies': [app,ScrollMagic, mobileDetect],
		'resolve': '#page-listing-expertises'
    },
	{
		'file': home, 
		'dependencies': [app, Swiper, isotope ],
		'resolve': '#page-home'
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

