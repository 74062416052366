/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|--------------------------------------------------------------------------------
|                                  Custom Google Map
|--------------------------------------------------------------------------------
|
| Scroller is a lightweight library to manage "animated & looped" sliding contents
| It uses Greensock as main dependency
|
*/

/*
|
| Class
|--------
|
*/
class customGoogleMap {
	/*
	|
	| Constructor
	|--------------
	*/
	constructor($map, params) {
		this.wrapper = $map;
		this.map    = false;
		this.markerCenter = [];
		this.params = params;
		this.defaultcenter = {lat:48.0809753, lng: 1.8426743}
		this.selectedInfoWindow = false;

		this.iwArray = [];
		this.startLatLng = false;
		this.searchArea = false;
		this.earchAreaMarker = false;
		this.iconGeolocalisation = false;
		this.currenthoverMarker = false;
		this.searchAreaRadius = 5000; // metres
		this.autocomplete = false; // metres
		this.init();
	}

	/*
	|
	| init
	|-------
	*/
	init() {

		var _this = this;
		var map = new google.maps.Map(_this.wrapper[0], {
			zoom: 1,
			scrollwheel: false,
			center: new google.maps.LatLng(0, 0),
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			styles: this.getCustomization(),
			streetViewControl: false,
			fullscreenControl: false,
			mapTypeControl: false
		});

		map.markers = [];

		$.each(this.params.markers, function (index) {
			_this.add_marker($(this), map,index);
		});

//				var markerCluster = new google.MarkerClusterer(map, this.marker);

		this.map = map;
		this.centerMap();


		if (this.params.iconGeo) {
			this.defaultIconMarkerGeo();
		}

		console.log(this.params.autoComplete);
		if (this.params.autoComplete) {
			this.defaultAutoComplete();
		}
	}


	defaultAutoComplete(){
        var autocomplete = new google.maps.places.Autocomplete(document.getElementById(this.params.autoComplete));
		autocomplete.addListener("place_changed", () => {
		    const place = autocomplete.getPlace();
	        if (!place.geometry || !place.geometry.location) {
				this.dispachEvent(this.wrapper, 'autocomplete:noAddress');
	        	return; 
			}
			this.dispachEvent(this.wrapper, 'autocomplete:succes', place.geometry.location);
	  });

	}


	defaultIconMarkerGeo(){
		this.iconGeolocalisation = {
			url: this.params.iconGeo,
			scaledSize: new google.maps.Size(25, 25),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(12, 12)
		};
	}
	/*
	|
	| add_marker
	|-------------
	*/
	add_marker($marker, map,index) {
		var _this = this;

		var _address    	= $marker.find('[data-address]'),
			_distance 		= '[data-distance]',
			_iconURL    	= $marker.data('icon'),
			_markerID    	= $marker.find('.item-card').attr('id')
		;

		var latlng = new google.maps.LatLng(_address.data('lat'), _address.data('lng'));

		var icon = {
			url: _iconURL,
			scaledSize: new google.maps.Size(36, 51),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(18, 51)
		};
		var marker = new google.maps.Marker({
			position: latlng,
			map: map,
			icon: icon
		});
		marker.$elt     = $marker;
		marker.markerID = _markerID;
		//marker.animation = this.setTimeline($marker);
		marker.distance = false;
		marker.setVisible(false);

		marker.cardCustom = {
			'data' : $marker.html(),
			'distance' : -1,
			'selectDistance' : _distance,
		};

		marker.infoWindow = new google.maps.InfoWindow({
			content : '<div class="map-card">' + $marker.html() + '</div>',
			minWidth: 340,
			disableAutoPan: true
		});

		google.maps.event.addListener(marker, 'click', function () {
			_this.dispachEvent(_this.wrapper, 'marker:click', marker );
			if (_this.selectedInfoWindow != false) {
					_this.selectedInfoWindow.infoWindow.close();
			}
			marker.infoWindow.open(map, marker);
			_this.selectedInfoWindow = marker;
		});
		map.markers.push(marker);

	}

	/*
	|
	| center_map
	|-------------
	*/
	centerMap() {
		const _this = this;
		const map = this.map;
		let count = 0;
		let note_index = false;
		let change_popup = true;
		var bounds = new google.maps.LatLngBounds();

		$.each(map.markers, function (i, marker) {
			if (marker.visible == true) {
				var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
				bounds.extend(latlng);
				count++;
			}
		});

		if (count == 1) {
			map.setCenter(bounds.getCenter());
			map.setZoom(this.params.zoom);
		}else if(count !=0 ){
			map.fitBounds(bounds);
			//map.setCenter(this.defaultcenter);
			//map.setZoom(this.params.zoom);
		}
		// si il y a un 
		if (note_index && change_popup && count != 0 ) {
			console.log('autre');
			console.log('note_index '+ note_index);
			_this.currentPopup = map.markers[note_index].$elt;
			this.dispachEvent(this.wrapper, 'marker:click', map.markers[note_index] );
		}

	}
	/*
	|
	| on lance l'affichage
	|-------------
	*/
	setTimeline($marker){
		let animation = new TimelineMax({  
			paused: true,
			onStart: function(){
			},
			onReverseComplete: function(){
			}
		})
		return animation;
	}

	
	/*
	|
	| on lance l'affichage
	|-------------
	*/
	start(){

		const markers = this.map.markers;
		var iwArray = [];

		this.deleteSearchArea();

		$.each(markers, function (i, marker) {
			marker.setVisible(true);
			iwArray.push(marker.cardCustom);
		});

	    this.iwArray = iwArray;
		this.centerMap();

	}


	/*
	|
	| Anim marker
	|-------------
	*/
	enableAnimMarker(nb){
		let _this = this,
			markers = this.map.markers
		;
		$.each(markers, function (i, marker) {
			if (marker.markerID == nb) {
				_this.currenthoverMarker = marker;
	    		marker.setAnimation(google.maps.Animation.BOUNCE);
			}
		});
	}

	/*
	|
	| Anim marker
	|-------------
	*/
	disableAnimMarker(){
		console.log(this.currenthoverMarker);
		if (this.currenthoverMarker != false) {
	    	this.currenthoverMarker.setAnimation(null);
	    	this.currenthoverMarker = false;
		}
	}


	/**
	|
	| On parcour les markers de la zone
	|-----------------------
	*/
	listCardCustom() {

		this.dispachEvent(this.wrapper, 'start:addCard' );
		
		const _this = this, 
			iwArray = this.iwArray
		;
		for (var i = 0; i < iwArray.length; i++) {
			_this.params.contentList.append(_this.createCardCustom(iwArray[i]));
		}

		this.dispachEvent(this.wrapper, 'end:addCard' );
	}

	/**
	|
	| créer les $elt a afficher a partir des infos du marqueur 
	|-----------------------
	*/

 	createCardCustom(_elt){
 		var $data = $(_elt.data).clone();
 		if (_elt.distance >= 0) {
 			let $seletor = $data.find(_elt.selectDistance);
 			$seletor.html(_elt.distance);
 			$seletor.parents('.detail-itineraire').removeClass('simple');
 		}
        return $data;
    }

	/**
	|
	| Tri des marques par la distance
	|-----------------------
	*/
	sortArrayByDistance(){
		var byDistance = this.iwArray.slice(0);
		byDistance.sort(function(a,b) {
		    return a.distance - b.distance;
		});

		this.iwArray = byDistance;
	}

	appendCard($fiche){

	}

	/**
	|
	| Rretourn ladresse a parti
	|-----------------------
	*/
	geolocalisation(pos) {
		this.dispachEvent(this.wrapper, 'start:changeMap');
		console.log('geolocalisation');
		const latlng = new google.maps.LatLng(parseFloat(pos.lat), parseFloat(pos.lng));
		this.startLatLng = latlng;
     	this.defineSearchArea(true);

	}

	/**
	|
	| Recherche un lieu avec une adresse
	|-----------------------
	*/
	geocodeAddress( _address ) {
		this.dispachEvent(this.wrapper, 'start:changeMap');
		console.log('geocodeAddress');
		const _this = this,
			map = this.map,
			geocoder = new google.maps.Geocoder()
		;
		geocoder.geocode({ address: _address }, (results, status) => {
			if (status === "OK") {
				_this.startLatLng = results[0].geometry.location;
		     	_this.defineSearchArea(false);
				status = results[0];
				_this.dispachEvent(_this.wrapper, 'result:geocoding', status );
			}else{
				_this.dispachEvent(_this.wrapper, 'erreur:geocoding');
			}
		});
	}


	deleteSearchArea(){
		if (this.earchAreaMarker != false) {
			this.earchAreaMarker.setMap(null);
		}
		if (this.searchArea != false) {
			this.searchArea.setMap(null);
		}
	}
	/**
	|
	| On créér et affiche la zone de recherche
	|-----------------------
	*/
	defineSearchArea(seeMarker){
		this.deleteSearchArea();
		this.earchAreaMarker = new google.maps.Marker({
			icon: this.iconGeolocalisation,
		    position: this.startLatLng,
		    map: this.map,
		    draggable: false,
		    title: 'searchAreaMarker'
	  	});
		   // animation: google.maps.Animation.DROP,
        //marker.setVisible(false);
		this.searchArea = new google.maps.Circle({
         	strokeColor: '#EC6611',
	        strokeOpacity: 0.1,
	        strokeWeight: 2,
	        fillColor: '#EC6611',
	        fillOpacity: 0.05,
	        map: this.map,
	        center: this.earchAreaMarker.getPosition(),
	        radius: this.searchAreaRadius
	    });
		this.findMarkersInArea();
	}

	/**
	|
	| On cherche les markers dans la zone
	|-----------------------
	*/
	findMarkersInArea() {
		// close open infowindows

		var bounds = new google.maps.LatLngBounds();

	   	const _this = this, 
	   		randomMarkers = this.map.markers,
	   		searchArea = this.searchArea,
	    	iwArray = []
	   	;

	    for (var i = 0; i < randomMarkers.length; i++) {
	    	let distance = google.maps.geometry.spherical.computeDistanceBetween(randomMarkers[i].getPosition(), searchArea.getCenter());
	      	if ( distance <= searchArea.getRadius()) {
		        console.log('=> is in searchArea');
		        randomMarkers[i].cardCustom.distance = Math.round(distance/1000);
		        //var iw = new google.maps.InfoWindow();
		        //iw.setContent("is in searchArea");
		        //iw.open(map, randomMarkers[i].marker);
		        randomMarkers[i].setVisible(true);
		        iwArray.push(randomMarkers[i].cardCustom);
		        var latlng = new google.maps.LatLng(randomMarkers[i].position.lat(), randomMarkers[i].position.lng());
				bounds.extend(latlng);

	      	} else {
		        //console.log('=> is NOT in searchArea');
		        //var iw = new google.maps.InfoWindow();
		        //iw.setContent("outside searchArea");
		        //iw.open(map, randomMarkers[i].marker);
		        //iwArray.push(iw);
	      	}
	    }
	    bounds.extend(this.earchAreaMarker.getPosition());
	    this.iwArray = iwArray;

     	this.map.setCenter(bounds.getCenter());
		this.map.setZoom(this.params.zoom);

		this.sortArrayByDistance();
		this.listCardCustom();

	}



	/**
	|
	| Helper: dispachEvent
	|-----------------------
	*/
	dispachEvent($element, eventName, datas = null){
		var event = $.Event(eventName);

		if(datas !== null){
			$.each(datas, function(key, value){
					event[key] = value
			});
		}
		$element.trigger(event);
	}





	/*
	|
	| getCustomization
	|-------------------
	*/
	getCustomization() {
			return [
			{
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#f5f5f5"
					}
				]
			},
			{
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#616161"
					}
				]
			},
			{
				"elementType": "labels.text.stroke",
				"stylers": [
					{
						"color": "#f5f5f5"
					}
				]
			},
			{
				"featureType": "administrative.land_parcel",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#bdbdbd"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#eeeeee"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#757575"
					}
				]
			},
			{
				"featureType": "poi.park",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#e5e5e5"
					}
				]
			},
			{
				"featureType": "poi.park",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#9e9e9e"
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#ffffff"
					}
				]
			},
			{
				"featureType": "road.arterial",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#757575"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#dadada"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#616161"
					}
				]
			},
			{
				"featureType": "road.local",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#9e9e9e"
					}
				]
			},
			{
				"featureType": "transit.line",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#e5e5e5"
					}
				]
			},
			{
				"featureType": "transit.station",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#eeeeee"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#c9c9c9"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#9e9e9e"
					}
				]
			}
		]
	}
}

export default customGoogleMap;
