/*** IMPORTS FROM imports-loader ***/
var define = false;

import ScrollTo from "gsap/ScrollToPlugin";

export default {
    init: (app, CookieManager, Swiper, Collapse, scrollTo) => {

        console.log(Collapse);
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $cookiePopup = $('.cookie-popup'),
            $cookieAccept = $cookiePopup.find('.item-close')
            ;



        /*
        |
        |  Scroll Section
        |-----------------
        |*/
        var $linkbottom = $(".scroll-section");
        $linkbottom.on('click', function (e) {
            e.preventDefault();
            let $section = $($(this).attr('href'));
            var _offset = $section.data('offset-top') ? $section.data('offset-top') : 50;
            var elemPos = $section.offset().top - _offset;
            TweenMax.to(window, 0.8, { scrollTo: elemPos, ease: Power1.easeOut });
        });

        /*
        |
        | testimonial
        |-----------
        */
        const
            $sliderTesti = $('.swiper-testi')
            ;

        if ($sliderTesti.length >= 1) {
            $sliderTesti.each(function () {
                let $slider = $(this);
                var slidertext = new Swiper($slider.find('.swiper-container'), {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    loopAdditionalSlides: 6,
                    simulateTouch: true,
                    spaceBetween: 30,
                    speed: 1200,
                    loop: true,
                    pagination: {
                    },
                    navigation: {
                        nextEl: $slider.find('.swiper-btn-next'),
                        prevEl: $slider.find('.swiper-btn-prev'),
                    }
                });
            });
        }

        /*
        |
        | Cookie Manager
        |-----------------
        */
        new CookieManager($cookiePopup, {
            name: 'adveris_cookie_use',
            duration: 1,
            buttons: {
                accept: $cookieAccept,
            },
            onAccept: () => {
                console.log('accpeted')
            },
            onRefuse: () => {
                console.log('refused')
            }
        });

        /*
        |
        | FAQS
        |-----------------
        */
        jQuery(document).ready(function () {
            jQuery(".section-faqs .schema-faq-section:first-child").addClass("active").children(".schema-faq-answer").slideDown();
            jQuery(".schema-faq-question").on("click", function () {
                if (jQuery(this).parent().hasClass("active")) {
                    jQuery(this).next().slideUp();
                    jQuery(this).parent().removeClass("active");
                }
                else {
                    jQuery(".schema-faq-answer").slideUp();
                    jQuery(".schema-faq-section").removeClass("active");
                    jQuery(this).parent().addClass("active");
                    jQuery(this).next().slideDown();
                }
            });
        });

        $body.on('loaderEnd', function () {
            var hash = $(location).attr('hash');
            if ($(hash).length > 0) {
                let $section = $(hash);
                var _offset = $section.data('offset-top') ? $section.data('offset-top') : 50;
                var elemPos = $section.offset().top - _offset;
                TweenMax.to(window, 0.2, { scrollTo: elemPos, ease: Power1.easeOut });
            }
        })
    }
}
