/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|--------------------------------------------------------------------------------
|                                  customCursor Google Map
|--------------------------------------------------------------------------------
|
|
*/

/*
|
| Class
|--------
|
*/
class customCursor {
	/*
	|
	| Constructor
	|--------------
	*/
	constructor($map, params) {
		this.wrapper = $map;
		this.map    = false;
		this.markerCenter = [];
		this.params = params;
	
		this.init();
	}

	/*
	|
	| init
	|-------
	*/
	init() {

		const $cursorOuter  = this.wrapper;

		console.log($cursorOuter);

        let lastY, lastX = 0;
        let magneticFlag = false;

        window.onmousemove = function (event) {
            if (!magneticFlag) {
                TweenMax.to($cursorOuter, 0.01, { x: event.pageX, y: event.clientY});
            }
            //TweenMax.to($cursorInner, 0.01,{ x: event.pageX, y: event.clientY });
            lastY = event.clientY;
            lastX = event.clientX;
        };
        // links hover
        $('body').on('mouseenter', '.btn-simple ', function() {
            //$cursorInner.addClass('cursor-link-hover');
            $cursorOuter.addClass('cursor-link-hover');
        });
        $('body').on('mouseleave', '.btn-simple ', function() {
            if ( $(this).is('a') && $(this).closest('.cursor-as-pointer').length ) {
                return;
            }
            //$cursorInner.removeClass('cursor-link-hover');
            $cursorOuter.removeClass('cursor-link-hover');
        });
        /*
        // magnet elements
        $('body').on('mouseenter', '.cursor-magnet--outer', function() {
            const $elem = $(this).find('.cursor-magnet');
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            $cursorOuter[0].style.transition = 'all .2s ease-out';
            TweenMax.to($cursorOuter, 0.2, { x: $elem.offset().left, y: ($elem.offset().top - scrollTop), ease: Power0.easeOut });

            $cursorOuter[0].style.width = $elem.width() + 'px';
            $cursorOuter[0].style.height = $elem.height() + 'px';
            $cursorOuter[0].style.marginLeft = 0;
            $cursorOuter[0].style.marginTop = 0;

            magneticFlag = true;
        });

        $('body').on('mouseleave', '.cursor-magnet--outer', function() {
            $cursorOuter[0].style.transition = null;
            $cursorOuter[0].style.width = null;
            $cursorOuter[0].style.height = null;
            $cursorOuter[0].style.marginLeft = null;
            $cursorOuter[0].style.marginTop = null;

            magneticFlag = false;
        });*/

	}


	defaultAutoComplete(){
    	
	}

}

export default customCursor;
