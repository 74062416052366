/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper, Isotope ) => {
   


    	/*
        |
        | galerie
        |-----------
        */
        const 
            $sliderOutils    = $('.swiper-outils'),
            $navExpertise    = $('.list-nav-expertise .item-expertise'),
            $sliderExpertise = $('.swiper-expertise'),
            interleaveOffset = 0.5
        ;

        if ($sliderOutils.length >= 1 ) {
            $sliderOutils.each(function(){
                let $slider = $(this);
                var slidertext = new Swiper ($slider.find('.swiper-container'), {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween:30,
                    speed: 1200,
                    loop: false,
                    breakpoints: {
                        692: {
                            slidesPerView: 7,
                            slidesPerGroup: 7
                        }
                    }
                });
            });
        }

        /*
        |
        | Slider expertise
        |------------
        */
        var mySwiper = new Swiper ($sliderExpertise.find('.swiper-container'), {
            slidesPerView: 1,
            slidesPerGroup: 1,
            speed: 600,
            spaceBetween: 0,
            loop: false,
            parallax: true,
            simulateTouch: true,
            on: {
                progress: function(){
                  let swiper = this;
                  for (let i = 0; i < swiper.slides.length; i++) {
                    let slideProgress = swiper.slides[i].progress,
                        innerOffset = swiper.width * interleaveOffset,
                        innerTranslate = slideProgress * innerOffset;
                        swiper.slides[i].querySelector(".adv-image").style.transform = "translateX(" + innerTranslate + "px)";
                  }
                },
                setTransition: function(speed) {
                    let swiper = this;
                    for (let i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].style.transition = speed + "ms";
                        swiper.slides[i].querySelector(".adv-image").style.transition = speed + "ms";
                    }
                }
            }
        });


        $navExpertise.mouseenter(function(){
            let _nb = $(this).index() + 1;
            let $slide = $sliderExpertise.find('.swiper-slide[data-slider='+_nb+']');
            if ($slide.length) {
                mySwiper.slideTo($slide.index());
            }
        });



    }

}   















